export default (randomId) => ({
    swiper: null,
    init() {
        setTimeout(() => {
            this.swiper = new Swiper(".swiper-container" + randomId, {
                loop: true,
                slidesPerView: 'auto',
                freeMode: true,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false,
                },
                speed: 5000,
                navigation: {
                    nextEl: ".swiper-right" + randomId,
                    prevEl: ".swiper-left" + randomId,
                },
            });
        }, 0);
    }
});